import React from "react";
import h2p from 'html2plaintext';
import Link from "gatsby-link";
import { graphql } from 'gatsby';
import TemplateWrapper from "../components/template-wrapper";
import MarkdownContent from "../components/markdown-content";

export default function indexMain({ data }) {
  let { edges: posts } = data.allMarkdownRemark;
  const headerImages = data.headerImages;
  const site = "https://blogs.cybersym.com";
  const share = {
    url: site,
    title: "CyberSym Technologies Website",
    excerpt: "The Technology Intersection blog explores all things technology — especially computer and information technology, biotechnology, communications, energy, transportation, nanotechnology, and robotics, to name a few.",
    realContent: true
  };
  const accumulation = (
    posts.reduce( ( accum, post ) => {
      if (accum === undefined)
        accum = "";
      if (post === undefined)
        return accum;

      return accum += " " + h2p(post.node.content);
    }, "")
  )

  return (
    <div className="palette--cst-site"> 
    <TemplateWrapper headerImages = {headerImages} accumulation = {accumulation} share={share} >  
      <h1 style={{paddingTop: "1.0rem", paddingBottom: "1.0rem", textDecoration: "none"}}><center>Welcome to the <em>CyberSym Technologies</em> Site!</center></h1> 
      <span>This is the place to find next generation pattern recognition, filtering, and artificial intelligence technology and applications.</span>
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .map( ({ node: post }) => {
          const title = MarkdownContent(post.frontmatter.title, "h2");
          const excerpt = (post.frontmatter.excerpt) ? MarkdownContent(post.frontmatter.excerpt, "blog-inner") : null;
          // counter = 0;
          return (
            <div className="blog-post-preview" key={post.id}>
              <h2>
                <center><Link to={post.frontmatter.path} >{title}</Link></center>
              </h2>
              {/* <h4>Written on {post.frontmatter.date}</h4>
              <h4>Tags:   
              {
                post.frontmatter.tags && 
                post.frontmatter.tags
                .map( tag => (
                  <span key={counter}>
                    { counter++ > 0 ? `, ` : ' '}
                    {tag}
                  </span>
              ))}
              </h4>   */}
              {/* <p dangerouslySetInnerHTML={{__html: excerpt}} /> */}
              { (excerpt) 
                ? excerpt
                : <div className="blog-inner" dangerouslySetInnerHTML={{__html: post.html}} />
              }
            </div>
          );
        })}
        <div style={{paddingBottom: "8.0rem"}} />
    </TemplateWrapper>
    </div>
  );
}

export const pageQuery = graphql`
  query indexMainQuery {
    allMarkdownRemark( 
      limit: 20, 
      sort: { 
        order: DESC, 
        fields: [frontmatter___date] 
      }
      filter: {
        frontmatter: { tags: { regex: "/info/" } }
      }
      ) {
      edges {
        node {
          excerpt(pruneLength: 500)
          html
          id
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            categories
            excerpt
          }
        }
      }
    }
    headerImages: allImageSharp(
      filter: {
        fluid: { originalName: { regex: "/cst/" } }
      }  
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 1600, maxHeight: 226) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }  
`;
  